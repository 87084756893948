<template>
  <div>
    <RightSideBar
      v-if="isOpen"
      close-button
      @close="isOpen = false"
      :loading="isLoading"
    >
      <template v-slot:title>
        <p class="text-darkPurple">Update Promotion</p>
      </template>
      <template>
        <div class="flex flex-col items-center">
          <loader size="xxs" :loader-image="false" />
        </div>
      </template>
    </RightSideBar>
    <template>
      <Promotion
        title="Update Promotion"
        ref="promotion"
        @close="isOpen = false"
        @promoted="$emit('promoted', $event)"
      />
    </template>
  </div>
</template>

<script>
export default {
  components: {
    RightSideBar: () => import("@/components/RightSideBar"),
    Promotion: () => import('./promotion'),
  },
  data(){
    return {
      isOpen: false,
      isLoading: true,
      employeeInfo: {},
      promotion: {},
    }
  },
  methods: {
    async toggle(payload){
      this.isOpen = true

      const promotion = {
        id: payload.newDetails.employee.id,
        userId: payload.newDetails.employee.userId,
        promotionId: payload.newDetails.employee.id,
        newLevel: payload.newDetails.newDesignation.level,
        newOfficeId: payload.newDetails.employee.newOfficeId,
        newManagerId: payload.newDetails.newManager.userId,
        functionId: payload.newDetails.newDesignation.functionId,
        effectiveDate: payload.newDetails.employee.effectiveDate,
        newAnnualSalary: payload.newDetails.employee.newAnnualSalary,
        newDesignationId: payload.newDetails.employee.newDesignationId,
      }

      this.$_getEmployeePayInfo(payload.userId).then(({ data }) => {
        const employeeInfo = {...payload, payInfo: data.payInfo}
        this.$refs.promotion.toggle({ employeeInfo, promotion })
        this.isOpen = false
      }).catch(() => {
        const employeeInfo = {...payload, payInfo: {}}
        this.$refs.promotion.toggle({ employeeInfo, promotion })
        this.isOpen = false
      })
    },
  }
}
</script>

<style>
.rightSidebar {
  width: 520px !important;
}
</style>
